<template>
  <div>
        <ed-profissional  :intProfissionalId="0" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdProfissional from "@/components/cadastro/profissional/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdProfissional },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>